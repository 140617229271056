import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageFlexible,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper';
import {
  FooterCta,
  CustomerLogos,
  EventCard,
  EventCardSet,
  HeroBanner,
  InfoBlocks,
  JazzHr,
  PlatformCard,
  PlatformCardSet,
  PlatformFeatureCards,
  PlatformFeatureDouble,
  PlatformFeatureIntro,
  PlatformFeatureLarge,
  PlatformFeatureSingle,
  PlatformFeatureSet,
  PlatformFeatureStats,
  PromoPopUp,
  RegistrationForm,
  ResourceCardSet,
  RichText,
  RichTextColumns,
  SolutionsCardCarousel,
  Spacer,
  StatsCloud,
  TabbedFeatures,
  TabbedResources,
  Testimonial,
  TextBlock,
  TextCta,
  TwoColumnRichText,
  WebinarGate,
  Undefined,
  Video,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageFlexibleProps {
  data?: ContentfulPageFlexible;
}
const PageFlexible: React.FC<PageFlexibleProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    let customSpacing;
    if (component.customSpacing) {
      customSpacing = component.customSpacing;
    }
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
          customSpacing={customSpacing}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data.pageHeader &&
        wrapBackground(
          data.pageHeader,
          <HeroBanner data={data.pageHeader as ContentfulEntry} />,
        )}
      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;
        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_CUSTOMER_LOGOS:
            element = <CustomerLogos key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_EVENT_CARD:
            element = <EventCard key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_EVENT_CARD_SET:
            element = <EventCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_HERO_BANNER:
            element = <HeroBanner key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_INFO_BLOCKS:
            element = <InfoBlocks key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_JAZZ_HR:
            element = <JazzHr key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_CARD:
            element = <PlatformCard key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_CARD_SET:
            element = <PlatformCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_CARDS:
            element = (
              <PlatformFeatureCards key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_DOUBLE:
            element = (
              <PlatformFeatureDouble key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_INTRO:
            element = (
              <PlatformFeatureIntro key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_LARGE:
            element = (
              <PlatformFeatureLarge key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_SINGLE:
            element = (
              <PlatformFeatureSingle key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_SET:
            element = (
              <PlatformFeatureSet key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_STATS:
            element = (
              <PlatformFeatureStats key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PROMO_POPUP:
            element = <PromoPopUp key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_FORM:
            element = <RegistrationForm key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT:
            element = <RichText key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT_COLUMNS:
            element = <RichTextColumns key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_SOLUTIONS_CAROUSEL:
            element = (
              <SolutionsCardCarousel key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_SPACER:
            element = <Spacer key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_STATS_CLOUD:
            element = <StatsCloud key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TABBED_FEATURES:
            element = <TabbedFeatures key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TABBED_RESOURCES:
            element = <TabbedResources key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TESTIMONIAL:
            element = <Testimonial key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TEXT_BLOCK:
            element = <TextBlock key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TWO_COLUMN_RICHTEXT:
            element = (
              <TwoColumnRichText key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_TEXT_CTA:
            element = <TextCta key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_WEBINARGATE:
            element = <WebinarGate key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_FOOTER_CTA:
            element = <FooterCta key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_VIDEO:
            element = <Video key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}
      {data.footerCta &&
        wrapBackground(data.footerCta, <FooterCta data={data.footerCta} />)}
      {/* <FooterCtaTall data={data.footerCta} /> */}
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageFlexible;
